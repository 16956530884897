import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

export default function MediaCard({
  url,
  children,
  eyebrow,
  title,
  excerpt,
  date,
  featuredImage,
}) {
  return (
    <Link to={url} className="group">
      <div className="flex flex-row flex-wrap -mx-4">
        <div className="w-8/12 xl:w-8/12 px-4">
          {eyebrow}
          <h3 className="text-lg lg:text-xl xl:text-2xl leading-snug font-medium group-hover:text-primary">
            {parse(title)}
          </h3>
          <div className="text-gray-500 mt-1 line-clamp-3">
            {parse(excerpt)}
          </div>
          <div className="mt-3">
            <time className="text-gray-500 text-sm">{date}</time>
          </div>
          {children}
        </div>
        <div className="w-4/12 xl:w-3/12 px-4">
          <div className="relative overflow-hidden bg-gray-100 rounded-md">
            <div style={{ paddingBottom: "56.25%" }}></div>
            {featuredImage}
          </div>
        </div>
      </div>
    </Link>
  )
}
