import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import Seo from "components/Seo"
import PageHeader from "components/PageHeader"

export default function BlogPostLayout({ title, description, children }) {
  const data = useStaticQuery(graphql`
    query WordPressBlogArchiveLayout {
      allWpCategory {
        nodes {
          id
          name
          slug
          uri
          count
        }
      }
    }
  `)

  const categories = data.allWpCategory.nodes

  return (
    <div>
      <Seo title={title} description={description} />
      <PageHeader title={title} description={description} />
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <div className="flex flex-row flex-wrap -mx-4">
          <div className="w-full lg:w-3/12 xl:w-2/12 px-4 mb-8 lg:mb-0">
            <div className="mb-5">
              <ul>
                <li>
                  <Link
                    to={`/insights`}
                    getProps={({ location }) => {
                      const pathnameSplited = location.pathname.split("/")
                      if (
                        pathnameSplited.includes("insights") &&
                        !pathnameSplited.includes("categories")
                      ) {
                        return { className: "text-gray-900" }
                      }
                    }}
                    className="text-gray-500 hover:text-gray-900"
                    activeClassName="text-gray-900"
                  >
                    All Insights
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-gray-900 text-lg font-medium mb-1">Topics</h3>

              <ul>
                {categories.map(item => {
                  if (!item.count) {
                    return null
                  }
                  return (
                    <li key={item.id} className="my-2" key={item.id}>
                      <Link
                        getProps={event => {
                          const { href, location } = event
                          const pathnameSplited = location.pathname.split("/")
                          if (
                            href === location.pathname ||
                            (href.split("/").splice(0, 4).join("/") ===
                              location.pathname &&
                              pathnameSplited.includes("insights") &&
                              pathnameSplited.includes("categories"))
                          ) {
                            return { className: "text-gray-900" }
                          }
                        }}
                        to={`/insights/categories/${item.slug}`}
                        className="text-gray-500 hover:text-gray-900"
                        activeClassName="text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-9/12 xl:w-9/12 2xl:w-8/12 px-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
